/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, rozmów bez limitu',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',
  extCode: 'PL',
  key: 1,
  newsSectionUrl: 'https://m.weezchat.pl/news',
  footerText: 'Weezchat subskrypcja : 33,21zl/tydz',
  footerLinks: [
    { value: 'Regulamin', href: 'https://m.weezchat.pl/regulamin' },
    { value: 'Polityka Prywatności', href: 'https://m.weezchat.pl/polityka_prywatnosci' },
    { value: 'Moje konto', href: 'https://m.weezchat.pl/moje_konto' },
    { value: 'Cookie', href: 'https://m.weezchat.pl/cookies' },
    { value: 'Info', href: 'https://m.weezchat.pl/info' },
  ],
  LP: 'http://promo.weezchat.pl/pwzchatpl/lp_weetic/',
  redirectIfNoIframe: null,
  useBothSignUpMethod: true,
  avatarSize: 1200,
  gtm: 'GTM-WXBDWZ',
  showAllCities: true,
  completeProfileInputs: ['phoneNumber'],
  RTL: false,
  unsubscribeLink: 'http://m.weezchat.pl/regulamin',
freeSubscription : false,
  home: {
    app: {
      available: true,
      linkGoogle: 'https://play.google.com/store/apps/details?id=com.virgoplay.tchat.weezchat&hl=pl',
      linkApple: '',
    },
    facebook: {
      available: true,
      link: 'https://www.facebook.com/ToolovPL/'
    },
    twitter: {
      available: true,
      link: 'https://twitter.com/Toolov_PL'
    },
    instagram: {
      available: false,
      link: ''
    }
  },
  RGPD : {
    cookies: {
      available: true,
      link: 'https://m.weezchat.pl/cookies'
    }
  }
};
